import * as React from 'react';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PeopleIcon from '@mui/icons-material/People';
import TvIcon from '@mui/icons-material/Tv';
import { CircularProgress, Button, Typography } from '@mui/material';
import axios from 'axios';

import Bulle from '../../../models/Bulle';
import Room from '../../../models/Room';
import useRoomService from '../../services/useRoomService';
import MiniRoomCard from '../salles/MiniRoomCard';

import styles from './OfficeMap.module.scss';

const roomStatusColors: { [key: string]: string } = {
    libre: 'green',
    occupée: 'red',
    'bientôt libre': 'orange',
    'bientôt occupée': 'orange',
    unknown: 'gray',
};

const bulleStatusColors: { [key: string]: string } = {
    off: '#39FF14',
    on: '#FF5733',
    indisponible: 'gray',
    unknown: 'gray',
};

const sensorBorderColors: { [key: string]: string } = {
    on: 'red',
    off: 'green',
    indisponible: 'white',
    unknown: 'white',
};

async function fetchBulles(): Promise<Bulle[]> {
    const { data } = await axios.get<Bulle[]>('/bulles');
    return data;
}

interface RoomDotProps {
    room: Room;
    floor: string;
}

interface BulleDotProps {
    bulle: Bulle;
    floor: string;
}

function RoomDot({ room, floor }: RoomDotProps) {
    const fetchRoomCalendarStatus = async (roomName: string): Promise<string> => {
        const { data } = await axios.get<string>(`/stats/${roomName}/current-status`);
        return data;
    };

    const fetchRoomSensorStatus = async (roomId: string): Promise<string> => {
        const { data } = await axios.get<string>(`/rooms/${roomId}/sensor-status`);
        return data;
    };

    const {
        data: roomCalendarStatus,
        isLoading: calendarLoading,
        error: calendarError,
    } = useQuery(['roomCalendarStatus', room.name], () => fetchRoomCalendarStatus(room.name!), {
        enabled: !!room.name,
    });

    const {
        data: roomSensorStatus,
        isLoading: sensorLoading,
        error: sensorError,
    } = useQuery(['roomSensorStatus', room.uuid], () => fetchRoomSensorStatus(room.uuid!), { enabled: !!room.uuid });

    const calendarColor = roomStatusColors[roomCalendarStatus?.toLowerCase() || 'unknown'];
    const sensorBorderColor = sensorBorderColors[roomSensorStatus?.toLowerCase() || 'unknown'];
    const ratioY = floor === 'ground' ? -8.6 : -7.4;
    const ratioX = floor === 'ground' ? 11.6 : 10.65;
    const coordinates = {
        top: `${100 - room.positionY! / ratioY}%`,
        left: `${room.positionX! / ratioX}%`,
    } || { top: '50%', left: '50%' };

    if (calendarLoading || sensorLoading) {
        return <CircularProgress />;
    }

    if (calendarError || sensorError) {
        return <div>Erreur lors du chargement du status de la salle</div>;
    }

    const roomPicture = room.imageName || 'conference_room.jpg';
    const isSensorOn = roomSensorStatus?.toLowerCase() === 'on';

    return (
        <div
            className={`${styles.roomDotContainer} ${isSensorOn ? styles.spinningBorder : ''}`}
            style={{ ...coordinates, borderColor: sensorBorderColor }}
            data-room-name={room.name}
            data-room-status={roomCalendarStatus}
            data-room-sensor-status={roomSensorStatus}
        >
            <div className={styles.roomDot} style={{ backgroundColor: calendarColor }} />
            <span className={styles.tooltip}>
                <MiniRoomCard
                    roomName={room.name!}
                    roomUuid={room.uuid!}
                    roomCapacity={room.capacity!}
                    roomSensorStatus={roomSensorStatus || 'unknown'}
                    roomStatus={roomCalendarStatus || 'unknown'}
                    roomPicture={roomPicture}
                />
            </span>
        </div>
    );
}

function BulleDot({ bulle, floor }: BulleDotProps) {
    const fetchBulleStatus = async (bulleId: string): Promise<string> => {
        const { data } = await axios.get<string>(`/bulles/${bulleId}/status`);
        return data;
    };

    const {
        data: bulleStatus,
        isLoading,
        error,
    } = useQuery(['bulleStatus', bulle.uuid], () => fetchBulleStatus(bulle.uuid!), { enabled: !!bulle.uuid });

    const ratioY = floor === 'ground' ? -8.6 : -7.4;
    const ratioX = floor === 'ground' ? 11.6 : 10.65;
    const coordinates = {
        top: `${100 - bulle.positionY! / ratioY}%`,
        left: `${bulle.positionX! / ratioX}%`,
    } || { top: '50%', left: '50%' };
    const dotColor = bulleStatusColors[bulleStatus?.toLowerCase() || 'unknown'];

    let statusText: string;
    let statusColor: string;

    if (bulleStatus?.toLowerCase() === 'on') {
        statusText = 'occupé';
        statusColor = 'red';
    } else if (bulleStatus?.toLowerCase() === 'off') {
        statusText = 'libre';
        statusColor = 'green';
    } else if (bulleStatus?.toLowerCase() === 'indisponible') {
        statusText = 'indisponible';
        statusColor = 'gray';
    } else {
        statusText = 'unknown';
        statusColor = 'gray';
    }

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>Erreur lors du chargement du status de la bulle</div>;
    }

    return (
        <div
            className={styles.bulleDot}
            style={{ ...coordinates, backgroundColor: dotColor }}
            data-bulle-name={bulle.name}
            data-bulle-status={bulleStatus}
        >
            <span className={styles.bulleTooltip}>
                <Typography component="h5" variant="h5" className={styles.title}>
                    {bulle.name}
                </Typography>
                <div>
                    <span style={{ color: statusColor }}>{statusText}</span>
                </div>
                <div>
                    <PeopleIcon style={{ verticalAlign: 'middle' }} /> {bulle.capacity}
                </div>
                <div>
                    <TvIcon style={{ verticalAlign: 'middle' }} />
                    {bulle.screen ? (
                        <CheckCircleIcon style={{ color: 'green', verticalAlign: 'middle' }} />
                    ) : (
                        <CancelIcon style={{ color: 'red', verticalAlign: 'middle' }} />
                    )}
                </div>
            </span>
        </div>
    );
}

export default function OfficeMap() {
    const { getRoomList } = useRoomService();
    const [currentFloor, setCurrentFloor] = useState('ground');
    const [transformWrapperRef, setTransformWrapperRef] = useState<any>(null);

    const { data: rooms, isLoading: roomsLoading, error: roomsError } = useQuery('rooms', () => getRoomList(50, 0));
    const { data: bulles, isLoading: bullesLoading, error: bullesError } = useQuery('bulles', fetchBulles);

    const floors = ['ground', 'first'];
    const currentFloorIndex = floors.indexOf(currentFloor);

    const handleFloorChange = (index: number) => {
        if (index >= 0 && index < floors.length) {
            const floor = floors[index];
            setCurrentFloor(floor);
            if (transformWrapperRef) {
                transformWrapperRef.resetTransform();
            }
        }
    };

    const filteredRooms = rooms?.filter((room) => (currentFloor === 'ground' ? room.etage === 0 : room.etage === 1));
    const filtredBulles = bulles?.filter((bulle) =>
        currentFloor === 'ground' ? bulle.etage === 0 : bulle.etage === 1,
    );

    useEffect(() => {
        if (transformWrapperRef) {
            transformWrapperRef.resetTransform();
        }
    }, [currentFloor]);

    if (roomsLoading || bullesLoading) {
        return <CircularProgress />;
    }

    if (roomsError || bullesError) {
        return <div>Erreur lors du chargement des salles ou des bulles</div>;
    }

    return (
        <div className={styles.container}>
            <div className={styles.floorName}> {currentFloor === 'ground' ? 'Open Space' : '1er Étage'} </div>
            <div className={styles.arrowContainer}>
                <Button
                    className={styles.arrow}
                    onClick={() => handleFloorChange(currentFloorIndex + 1)}
                    disabled={currentFloorIndex === floors.length - 1}
                >
                    <ArrowUpwardIcon style={{ color: 'black' }} />
                </Button>
                <Button
                    className={styles.arrow}
                    onClick={() => handleFloorChange(currentFloorIndex - 1)}
                    disabled={currentFloorIndex === 0}
                >
                    <ArrowDownwardIcon style={{ color: 'black' }} />
                </Button>
            </div>
            <div className={styles.mapWrapper}>
                <TransformWrapper
                    initialScale={currentFloor === 'ground' ? 1 : 0.8}
                    minScale={0.3}
                    maxScale={3}
                    centerOnInit
                    wheel={{ step: 0.1 }}
                    doubleClick={{ disabled: true }}
                    pinch={{ step: 0.1 }}
                    ref={setTransformWrapperRef}
                >
                    <TransformComponent>
                        <div
                            className={`${styles.mapContainer} ${
                                currentFloor === 'ground' ? styles.openSpaceMap : styles.etageMap
                            }`}
                        >
                            <img
                                src={currentFloor === 'ground' ? '/Plan%20Taki.png' : '/etage.png'}
                                alt="Office Map"
                                className={styles.mapImage}
                            />
                            {filteredRooms?.map((room) => (
                                <RoomDot key={room.uuid!} room={room} floor={currentFloor} />
                            ))}
                            {filtredBulles?.map((bulle) => (
                                <BulleDot key={bulle.uuid!} bulle={bulle} floor={currentFloor} />
                            ))}
                        </div>
                    </TransformComponent>
                </TransformWrapper>
            </div>
        </div>
    );
}
